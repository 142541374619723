<template>
  <section class="space-1 bg-light login-section">
    <div class="container">
      <div class="d-flex justify-content-center mb-5">
        <router-link to="/">
          <img class="logo d-flex"
               src="@/assets/img/brand/logo.svg" alt="Logo">
        </router-link>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-5 card card-body py-5 px-5">
          <div class="px-3 py-3">
            <div class="mb-4">
              <h3>Login</h3>
              <p class="mt-3 text-muted">
                The simplest way to keep track of stuff!</p>
            </div>
            <div class="form-group">
              <label for="email">Email address</label>
              <input type="email"
                     v-on:keyup.enter="loginFromTextbox()"
                     v-model="username" class="form-control" id="email" required>
              <span v-if="notValidEmail"
                    class="red small">Username needs to be a valid email address.</span>
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input type="password" class="form-control"
                     v-on:keyup.enter="loginFromTextbox()"
                     v-model="password" id="password" required>
              <div class="text-right">
                <router-link to="/forgot"><small>Forgot password?</small></router-link>
              </div>
            </div>
            <button @click="loginUser()"
                    :disabled="loggingIn || !username || !password"
                    class="btn btn-primary btn-block">Login</button>
            <span v-if="loginErrorMessage" class="red small">{{loginErrorMessage}}</span>
            <div v-if="loggingIn" class="mt-2 progress-line blue-bar"></div>
            <div class="mt-5 text-center">
              <p class="m-0">Don't have an account?</p>
              <router-link to="/signup">Create account</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import auth from '@/app/auth';
import utils from '@/app/utils';

export default {
  data() {
    return {
      username: null,
      password: null,
      loggingIn: false,
      loginErrorMessage: '',
      notValidEmail: false,
    };
  },
  methods: {
    async loginUser() {
      this.loginErrorMessage = '';
      this.notValidEmail = false;

      if (!this.username || !this.password) {
        return;
      }

      if (!utils.validateEmail(this.username)) {
        this.notValidEmail = true;
        return;
      }

      const redirectUrl = this.$route.query.redirect;
      this.loggingIn = true;
      try {
        await auth.loginUser(this.username, this.password, redirectUrl);
        this.loggingIn = false;
      } catch (error) {
        console.log('ERROR RECEIVED', error);
        this.loginErrorMessage = error.message;
        this.loggingIn = false;
      }
    },
    async loginFromTextbox() {
      if (this.username && this.password) {
        await this.loginUser();
      }
    },
  },
};
</script>
